<template>
	<div>
		<BackButton :route="{ name: 'content-forms', label: 'Forms' }" />

		<div class="md:flex md:items-center md:justify-between my-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Edit Form
					<span class="ml-4 text-blue-500">
						{{ formName }}
					</span>
				</h2>
			</div>
			<div class="ml-4 mt-2 flex-shrink-0">
				<!-- <button
					type="button"
					class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none"
					@click="openPreview"
				>
					<ExternalLinkIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Preview Page
				</button> -->
			</div>
		</div>

		<div class="flex gap-x-5">
			<div class="mt-5 flex-1 bg-white border border-gray-300 rounded-lg">
				<div class="bg-gray-200 border-b border-gray-300 rounded-t-lg">
					<h3 class="py-2 px-5 text-lg leading-6 font-medium text-gray-900">
						Form Settings
					</h3>
				</div>
				<div class="px-4 py-3 rounded-md">
					<div>
						<h2 class="border-b border-gray-200 mb-3 pb-1">
							General
						</h2>
						<div class="flex flex-wrap gap-5">
							<div class="flex-1">
								<label for="formName" class="block font-medium text-sm text-gray-700">
									Form Name
								</label>
								<div class="mt-1">
									<input
										v-model="formName"
										type="text"
										name="formName"
										class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
								<div class="text-sm text-gray-400">
									The form name is only used for internal reference.
								</div>
							</div>

							<div class="flex-none">
								<label for="formSubmitlabel" class="block font-medium text-sm text-gray-700">
									Submit Button Text
								</label>
								<div class="mt-1">
									<input
										v-model="formSubmitlabel"
										type="text"
										name="formSubmitlabel"
										class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>

							<div class="flex-none">
								<label for="formCols" class="block font-medium text-sm text-gray-700">
									Number of Columns
								</label>
								<div class="mt-1">
									<select
										v-model="formCols"
										name="formCols"
										class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
									>
										<option value="1">1</option>
										<option value="2">2</option>
									</select>
								</div>
							</div>

							<div class="flex-1">
								<label for="formInstructions" class="block font-medium text-sm text-gray-700">
									Form Instructions
									<span class="text-sm text-gray-400">(optional)</span>
								</label>
								<div class="mt-1">
									<textarea
										v-model="formInstructions"
										type="text"
										name="formInstructions"
										rows="4"
										class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md resize-none"
									></textarea>
								</div>
							</div>

							<div class="flex-none relative flex items-start py-3">
								<div class="flex items-center h-5">
									<input
										v-model="formCaptcha"
										name="formCaptcha"
										type="checkbox"
										class="focus:ring-transparent h-4 w-4 text-blue-600 border-gray-300 rounded"
									/>
								</div>
								<div class="ml-3 text-sm">
									<label for="formCaptcha" class="font-medium text-gray-700">
										Use Google reCAPTCHA
									</label>
									<p class="text-gray-500">
										User will be required pass a captcha
										<br />
										before submitting the form.
									</p>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div class="grid grid-cols-5">
							<SwitchGroup as="div" class="flex items-center justify-between">
								<span class="flex-grow flex flex-col">
									<SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
										For Events
									</SwitchLabel>
									<SwitchDescription as="span" class="text-sm text-gray-500">
										Will this form be used for an event?
									</SwitchDescription>
								</span>
								<Switch
									v-model="forEvent"
									:class="[
										forEvent ? 'bg-blue-600' : 'bg-gray-200',
										'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
									]"
								>
									<span
										aria-hidden="true"
										:class="[
											forEvent ? 'translate-x-5' : 'translate-x-0',
											'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
										]"
									/>
								</Switch>
							</SwitchGroup>
						</div>

						<div v-if="!forEvent" class="mt-5">
							<h2 class="border-b border-gray-200 mb-3 pb-1">
								Additional Settings
							</h2>

							<div>
								<FieldsInputNumber
									v-model="formMaxSubmissions"
									:field="{
										type: 'input',
										inputType: 'number',
										label: 'Max Submissions',
										model: 'max_submissions',
										required: false,
										min: 0,
										max: 10000,
										step: 1,
										colSpan: 'col-span-6',
									}"
								/>

								<FieldsUser
									v-model="formReporter"
									:field="{
										label: 'Send Reporting Emails To',
										model: 'user_id',
										colSpan: 'col-span-6',
										required: true,
									}"
								/>

								<FieldsInput
									v-model="formConfirmationMessage"
									:field="{
										type: 'input',
										inputType: 'text',
										label: 'Confirmation Message',
										model: 'formConfirmationMessage',
										required: true,
										colSpan: 'col-span-6',
									}"
								/>

								<div class="grid grid-cols-2 gap-x-8">
									<div class="col-span-1">
										<div class="border-b pb-2 mt-5 mb-3">
											<h3 class="text-lg leading-6 font-semibold text-gray-700">
												Confirmation Email
											</h3>
											<p class="mt-1 text-sm text-gray-500">
												This information will be used to send an event registration confirmation
												email to the user.
											</p>
										</div>

										<FieldsEmailTemplate
											v-model="formConfirmationEmailTemplate"
											:field="{
												label: 'Email Template',
												model: 'formConfirmationEmailTemplate',
												colSpan: 'col-span-6',
												required: true,
												type: 'Confirmation',
												categorySelect: true,
											}"
										/>

										<!-- <FieldsInput
											v-model="formConfirmationEmailSubject"
											:field="{
												type: 'input',
												inputType: 'text',
												label: 'Email Subject',
												model: 'formConfirmationEmailSubject',
												required: true,
												colSpan: 'col-span-6',
											}"
										/> -->

										<!-- <FieldsInput
											v-model="formConfirmationEmailMessage"
											:field="{
												type: 'input',
												inputType: 'text',
												label: 'Email Message',
												model: 'formConfirmationEmailMessage',
												required: true,
												colSpan: 'col-span-6',
											}"
										/> -->
									</div>

									<div class="col-span-1">
										<div class="border-b pb-2 mt-5 mb-3">
											<h3 class="text-lg leading-6 font-semibold text-gray-700">
												Reporting Email
											</h3>
											<p class="mt-1 text-sm text-gray-500">
												This information will be used to send an event registration email to the
												event coordinator.
											</p>
										</div>

										<FieldsEmailTemplate
											v-model="formReportingEmailTemplate"
											:field="{
												label: 'Email Template',
												model: 'formReportingEmailTemplate',
												colSpan: 'col-span-6',
												required: true,
												type: 'Reporting',
												categorySelect: true,
											}"
										/>

										<!-- <FieldsInput
											v-model="formReportingEmailSubject"
											:field="{
												type: 'input',
												inputType: 'text',
												label: 'Email Subject',
												model: 'formReportingEmailSubject',
												required: true,
												colSpan: 'col-span-6',
											}"
										/> -->

										<!-- <FieldsInput
											v-model="formReportingEmailMessage"
											:field="{
												type: 'input',
												inputType: 'text',
												label: 'Email Message',
												model: 'formReportingEmailMessage',
												required: true,
												colSpan: 'col-span-6',
											}"
										/> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="mt-5 flex-none flex flex-col bg-white border border-gray-300 rounded-lg divide-y divide-gray-200"
			>
				<div class="bg-gray-200 rounded-t-lg">
					<h3 class="py-2 px-5 text-lg leading-6 font-medium text-gray-900">
						Publish Status
					</h3>
				</div>
				<div class="px-4 py-5 mb-auto">
					<div v-if="!formPublished" class="text-center text-yellow-600 font-semibold text-2xl">
						Unpublished
					</div>
					<div v-if="formPublished" class="text-center font-semibold">
						<span class="text-2xl text-green-600">Published</span>
						<br />
						<span class="text-sm text-gray-500">{{ formPublished.publish_date_time }}</span>
					</div>
				</div>
				<div class="footer">
					<div v-if="dirty" class="px-2 py-4 text-center text-red-500">
						There are changes that need to be saved
					</div>

					<button
						v-if="!formPublished"
						class="px-4 py-2 h-10 w-full text-center text-white font-medium leading-6 bg-green-600 hover:bg-green-700 rounded-b-lg disabled:opacity-50"
						:disabled="dirty"
						@click="publish"
					>
						Publish
					</button>

					<div class="flex">
						<button
							v-if="formPublished"
							class="flex-none px-4 py-2 h-10 text-center text-white font-medium leading-6 bg-red-600 hover:bg-red-700 rounded-bl-lg disabled:opacity-50"
							:disabled="dirty"
							@click="archive"
						>
							Unpublish
						</button>
						<button
							v-if="formPublished"
							class="flex-1 px-4 py-2 h-10 text-center text-white font-medium leading-6 bg-green-600 hover:bg-green-700 rounded-br-lg disabled:opacity-50"
							:disabled="dirty"
							@click="publish"
						>
							Publish Revision
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="grid grid-cols-12 gap-x-4 my-5">
			<div class="col-span-9 3xl:col-span-8 bg-white border border-gray-300 rounded-lg">
				<div class="bg-gray-200 border-b border-gray-300 rounded-t-lg">
					<h3 class="py-2 px-5 text-lg leading-6 font-medium text-gray-900">
						Form
					</h3>
				</div>
				<div class="p-2 rounded-md">
					<draggable
						v-if="form && form.length > 0"
						:list="form"
						item-key="id"
						class="relative gap-y-5"
						handle=".handle"
						:class="[formCols === '1' ? 'flex flex-col' : 'grid grid-cols-2 gap-x-2']"
						@start="drag = true"
						@end="drag = false"
						@change="itemMoved"
					>
						<template #item="{ element, index }">
							<div class="flex-1 flex gap-y-2 gap-x-2 p-2 rounded-md shadow bg-gray-50">
								<FormBuilderFieldGroup
									:key="element.name"
									v-model="form[index]"
									:field-group-index="index"
									class="flex-1"
									@editField="editField"
								/>

								<div class="flex-none items-center">
									<SwitchVerticalIcon
										class="h-5 w-5 text-gray-400 hover:text-gray-500 handle"
										aria-hidden="true"
									/>
								</div>
							</div>
						</template>
					</draggable>

					<div v-if="availableFields.value" class="mt-5 first:mt-0">
						<FormBuilderFieldSelector @addField="addField" @addFieldGroup="addFieldGroup" />
					</div>

					<button
						v-if="form && form.length > 0"
						type="button"
						class="mt-5 inline-flex px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none"
					>
						{{ formSubmitlabel }}
					</button>
				</div>
			</div>

			<div class="col-span-3 3xl:col-span-4 bg-white border border-gray-300 rounded-lg">
				<div class="bg-gray-200 border-b border-gray-300 rounded-t-lg">
					<h3 class="py-2 px-5 text-lg leading-6 font-medium text-gray-900">
						Field Settings
					</h3>
				</div>
				<div class="p-2 rounded-md">
					<div v-if="Object.keys(fieldSettings).length > 0" class="sticky top-0">
						<div id="field-settings" class="field-settings flex flex-col gap-y-4">
							<h2 class="pb-2 border-b">
								<strong>Field Type:</strong>
								<span class="pl-2 text-gray-700">{{ fieldSettings.field_type.name }}</span>
							</h2>
							<div>
								<label for="label" class="block text-sm font-medium text-gray-700">
									Label
								</label>
								<div class="mt-1">
									<input
										v-model="fieldSettings.label"
										type="text"
										name="label"
										class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>

							<div v-if="['Text', 'Textarea', 'Number'].includes(fieldSettings.field_type.name)">
								<label for="placeholder" class="block text-sm font-medium text-gray-700">
									Placeholder
								</label>
								<div class="mt-1">
									<input
										v-model="fieldSettings.placeholder"
										type="text"
										name="placeholder"
										class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>

							<div
								v-if="
									['Text', 'Textarea', 'Select', 'Telephone', 'Email', 'File Upload'].includes(
										fieldSettings.field_type.name
									)
								"
							>
								<label for="help" class="block text-sm font-medium text-gray-700">
									Help Text
								</label>
								<div class="mt-1">
									<input
										v-model="fieldSettings.tooltip"
										type="text"
										name="help"
										class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>

							<div
								v-if="['Checkbox', 'Select', 'Radio'].includes(fieldSettings.field_type.name)"
								class="relative mt-5 border-2 border-blue-300 border-dashed rounded-md"
							>
								<div
									class="absolute -top-3 left-2 py-1 px-2 bg-blue-50 border-2 border-blue-300 border-dashed rounded-md"
								>
									{{ fieldSettings.field_type.name }}
									Choices
								</div>
								<div class="pt-8 pb-5 px-3">
									<draggable
										:list="fieldSettings.field_choices"
										handle=".handle"
										item-key="index"
										class="flex flex-col gap-y-5"
										@change="updateOptions"
									>
										<template #item="{ element, index }">
											<div class="flex gap-x-5">
												<div class="flex-1">
													<input
														v-model="element.choice_value"
														type="text"
														:name="element.choice_value"
														class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
													/>
												</div>

												<div class="flex gap-x-5 justify-center items-center">
													<button
														type="button"
														class="flex-1 inline-flex px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none"
														@click="addOption(index)"
													>
														Add
													</button>

													<button
														type="button"
														class="flex-1 inline-flex px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none disabled:opacity-50"
														:disabled="index === 0"
														@click="deleteOption(index)"
													>
														Delete
													</button>

													<div class="flex-none flex justify-center items-center">
														<SwitchVerticalIcon
															class="h-5 w-5 text-gray-400 handle"
															aria-hidden="true"
														/>
													</div>
												</div>
											</div>
										</template>
									</draggable>
								</div>
							</div>

							<div
								v-if="fieldSettings.field_type.name === 'File Upload'"
								class="relative mt-5 border-2 border-blue-300 border-dashed rounded-md"
							>
								<div
									class="absolute -top-3 left-2 py-1 px-2 bg-blue-50 border-2 border-blue-300 border-dashed rounded-md"
								>
									File Types Allowed
								</div>
								<div class="pt-8 pb-5 px-3">
									<div class="flex flex-col gap-y-5">
										<div class="relative flex items-start">
											<div class="flex items-center h-5">
												<input
													id="fieldSettingAllowImages"
													name="fieldSettingAllowImages"
													type="checkbox"
													class="focus:ring-transparent h-4 w-4 text-blue-600 border-gray-300 rounded"
													:checked="fieldSettingAllowImages"
													@change="updateValidationFile($event, 'images')"
												/>
											</div>
											<div class="ml-3 text-sm">
												<label for="fieldSettingAllowImages" class="font-medium text-gray-700">
													Images
												</label>
												<p class="text-gray-500">
													GIF, JPEG, JPG, PNG
												</p>
											</div>
										</div>

										<div class="relative flex items-start">
											<div class="flex items-center h-5">
												<input
													id="fieldSettingAllowDocuments"
													name="fieldSettingAllowDocuments"
													type="checkbox"
													class="focus:ring-transparent h-4 w-4 text-blue-600 border-gray-300 rounded"
													:checked="fieldSettingAllowDocuments"
													@change="updateValidationFile($event, 'documents')"
												/>
											</div>
											<div class="ml-3 text-sm">
												<label
													for="fieldSettingAllowDocuments"
													class="font-medium text-gray-700"
												>
													Documents
												</label>
												<p class="text-gray-500">
													PDF, DOC, DOCX, ODT
												</p>
											</div>
										</div>

										<div class="relative flex items-start">
											<div class="flex items-center h-5">
												<input
													id="fieldSettingAllowVideos"
													name="fieldSettingAllowVideos"
													type="checkbox"
													class="focus:ring-transparent h-4 w-4 text-blue-600 border-gray-300 rounded"
													:checked="fieldSettingAllowVideos"
													@change="updateValidationFile($event, 'videos')"
												/>
											</div>
											<div class="ml-3 text-sm">
												<label for="fieldSettingAllowVideos" class="font-medium text-gray-700">
													Videos
												</label>
												<p class="text-gray-500">
													MP4, M4V, MOV, WMV, AVI, MPG, OGG
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="relative mt-5 border-2 border-gray-300 border-dashed rounded-md">
								<div
									class="absolute -top-3 left-2 py-1 px-2 bg-gray-100 border-2 border-gray-300 border-dashed rounded-md"
								>
									Validation Rules
								</div>
								<div class="pt-8 pb-5 px-3 flex flex-col gap-y-5">
									<div class="relative flex items-start">
										<div class="flex items-center h-5">
											<input
												name="required"
												type="checkbox"
												class="focus:ring-transparent h-4 w-4 text-blue-600 border-gray-300 rounded"
												:checked="isRequired"
												@change="updateValidationRequired"
											/>
										</div>
										<div class="ml-3 text-sm">
											<label for="required" class="font-medium text-gray-700">Required</label>
											<p class="text-gray-500">
												User will be required to fill this field.
											</p>
										</div>
									</div>

									<div
										v-if="['Text', 'Textarea', 'Checkbox'].includes(fieldSettings.field_type.name)"
									>
										<div
											class="relative flex flex-col bg-gray-100 border border-gray-200 p-2 rounded-md"
										>
											<div class="text-sm">
												<label class="font-medium text-gray-700">Range</label>
												<p class="text-gray-500">
													You can specify the minimum and maximum character input.
												</p>
											</div>
											<div class="mt-2 flex flex-wrap gap-x-5 gap-y-5">
												<div class="flex-1">
													<label for="min" class="block text-sm font-medium text-gray-700">
														Min
													</label>
													<input
														v-model="fieldValidationMin"
														type="number"
														name="min"
														min="0"
														class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
														@input="updateValidationMinChar"
													/>
												</div>

												<div class="flex-1">
													<label for="max" class="block text-sm font-medium text-gray-700">
														Max
													</label>
													<input
														v-model="fieldValidationMax"
														type="number"
														name="max"
														min="1"
														class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
														@input="updateValidationMaxChar"
													/>
												</div>
											</div>
										</div>
									</div>

									<div v-if="['date'].includes(fieldSettings.field_type.name)">
										<div
											class="relative flex flex-col bg-gray-100 border border-gray-200 p-2 rounded-md"
										>
											<div class="text-sm">
												<label class="font-medium text-gray-700">Date Range</label>
												<p class="text-gray-500">
													You can specify the minimum and maximum date users can select
													between.
												</p>
											</div>

											<div class="mt-2 flex flex-wrap gap-x-5">
												<div class="flex-1">
													<label for="min" class="block text-sm font-medium text-gray-700">
														Min
													</label>
													<v-date-picker
														v-model="fieldValidationMinDate"
														:timezone="'America/Chicago'"
													/>
													<div class="mt-1">
														<button
															type="button"
															class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
															@click="fieldValidationMinDate = null"
														>
															Reset Min Date
														</button>
													</div>
												</div>

												<div class="flex-1">
													<label for="min" class="block text-sm font-medium text-gray-700">
														Max
													</label>
													<v-date-picker
														v-model="fieldValidationMaxDate"
														:timezone="'America/Chicago'"
													/>
													<div class="mt-1">
														<button
															type="button"
															class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
															@click="fieldValidationMaxDate = null"
														>
															Reset Max Date
														</button>
													</div>
												</div>
											</div>

											<div
												v-show="dateRangeError"
												class="mt-4 rounded-md bg-red-50 border border-red-100 p-4"
											>
												<div class="flex">
													<div class="flex-shrink-0">
														<XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
													</div>
													<div class="ml-3">
														<h3 class="text-sm font-medium text-red-800">
															Max Date must be after Min Date
														</h3>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="px-4 py-5 shadow-sm border border-gray-300 rounded-l-md flex justify-end fixed bottom-5 right-4 bg-white bg-opacity-50 z-10"
		>
			<button
				type="button"
				class="inline-flex px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none disabled:opacity-50"
				:disabled="!formValid"
				@click="update"
			>
				<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
				Update
			</button>
		</div>

		<form id="preview" :action="previewUrl" method="POST" target="_blank" class="hidden">
			<input id="preview-data" v-model="preview" type="hidden" name="preview" />
		</form>
	</div>
</template>

<script>
import { ref, reactive, computed, watch, provide, inject, getCurrentInstance, onMounted } from 'vue'
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import getEnv from '@/extensions/env'

import draggable from 'vuedraggable'

import ToastsFormError from '@/components/Toasts/FormError'

import { SwitchVerticalIcon, XCircleIcon, SaveIcon } from '@heroicons/vue/solid'

export default {
	components: {
		draggable,
		SwitchVerticalIcon,
		XCircleIcon,
		Switch,
		SwitchDescription,
		SwitchGroup,
		SwitchLabel,
		SaveIcon,
	},
	setup() {
		const root = getCurrentInstance()
		const route = useRoute()
		const api = inject('api')
		const toast = useToast()
		const forEvent = ref(true)
		const forEventOriginal = ref(true)

		const previewUrl = getEnv('VUE_APP_FRONTEND_URL') + '/form-preview'
		const preview = ref(null)

		const drag = ref(false)
		const dirty = ref(false)
		const form = ref([])
		const formID = ref(null)
		const formOriginal = ref([])
		const formPublished = ref(null)
		const formRevisionId = ref(null)
		const formCols = ref('1')
		const formColsOriginal = ref('1')
		const formName = ref('Untitled Form')
		const formNameOriginal = ref('Untitled Form')
		const formInstructions = ref(null)
		const formInstructionsOriginal = ref(null)
		const formCaptcha = ref(true)
		const formCaptchaOriginal = ref(true)
		const formSubmitlabel = ref('Submit')
		const formSubmitlabelOriginal = ref('Submit')

		const formMaxSubmissions = ref(null)
		const formMaxSubmissionsOriginal = ref(null)
		const formReporter = ref(null)
		const formReporterOriginal = ref(null)
		const formConfirmationMessage = ref(null)
		const formConfirmationMessageOriginal = ref(null)

		const formReportingEmailId = ref(null)
		const formReportingEmailTemplate = ref(null)
		const formReportingEmailTemplateOriginal = ref(null)
		const formReportingEmailSubject = ref(null)
		const formReportingEmailSubjectOriginal = ref(null)
		const formReportingEmailMessage = ref(null)
		const formReportingEmailMessageOriginal = ref(null)

		const formConfirmationEmailId = ref(null)
		const formConfirmationEmailTemplate = ref(null)
		const formConfirmationEmailTemplateOriginal = ref(null)
		const formConfirmationEmailSubject = ref(null)
		const formConfirmationEmailSubjectOriginal = ref(null)
		const formConfirmationEmailMessage = ref(null)
		const formConfirmationEmailMessageOriginal = ref(null)

		const fieldSettings = ref({})
		const fieldSettingsOffset = ref(0)
		const fieldIndex = ref(-1)
		const fieldSettingsValidation = reactive([])
		const fieldValidationMin = ref(0)
		const fieldValidationMax = ref(null)

		const dateRangeError = ref(false)
		const fieldValidationMinDate = ref(null)
		const fieldValidationMaxDate = ref(null)

		const dateRangeFormError = ref(false)
		const formStartDate = ref(null)
		const formEndDate = ref(null)

		const fieldTel = reactive({
			type: 'tel',
			labelClass: 'form-label',
			inputClass: 'form-input-tel',
			label: 'Untitled',
			name: '',
			placeholder: '(999) 999-9999',
			// validation: "[['matches', /^\\(\\d{3}\\)\\s\\d{3}-\\d{4}$/]]",
			// rules: ["['matches', /^\\(\\d{3}\\)\\s\\d{3}-\\d{4}$/]"],
			validation: 'matches:/^\\(\\d{3}\\)\\s\\d{3}-\\d{4}$/',
			rules: ['matches:/^\\(\\d{3}\\)\\s\\d{3}-\\d{4}$/'],
			'validation-messages': {
				matches: 'Please use the correct phone format (999) 999-9999',
			},
			'@input': 'phone-input',
		})
		const fieldEmail = reactive({
			type: 'email',
			labelClass: 'form-label',
			inputClass: 'form-input-email',
			label: 'Untitled',
			name: '',
			placeholder: 'user@example.com',
			validation: 'email',
			rules: ['email'],
		})

		onMounted(() => {
			fetchAvailableFields()

			formID.value = route.params.id
			console.log('formID', formID.value)

			console.log('mounted!')
		})

		const availableFields = reactive([])
		provide(
			'availableFields',
			computed(() => availableFields.value)
		)
		const fetchAvailableFields = () => {
			root.ctx.loaderShow()

			api.forms
				.available_fields()
				.then(data => {
					availableFields.value = data

					fetchForm()
				})
				.catch(error => {
					toast.error('Error fetching available fields:\n' + error)
					// toast.error('Error fetching available fields')
				})
				.then(() => {
					root.ctx.loaderHide()
				})
		}

		const fetchForm = () => {
			// console.log(root.ctx.$route)
			// return false
			root.ctx.loaderShow()

			api.forms
				.slug(formID.value)
				.fetch()
				.then(data => {
					formPublished.value = data.form_revision_published
					formRevisionId.value = data.form.id
					formCols.value = data.column_count.toString()
					formColsOriginal.value = data.column_count.toString()
					formName.value = data.name
					formNameOriginal.value = data.name
					formInstructions.value = data.instructions
					formInstructionsOriginal.value = data.instructions
					formSubmitlabel.value = data.submit_label
					formSubmitlabelOriginal.value = data.submit_label
					formCaptcha.value = data.captcha
					formCaptchaOriginal.value = data.captcha

					// Weird fix to get the "for events" switch working
					setTimeout(() => {
						forEvent.value = data.for_event
						forEventOriginal.value = data.for_event
					}, 300)

					formMaxSubmissions.value = data.max_submissions
					formMaxSubmissionsOriginal.value = data.max_submissions
					formReporter.value = data.user_id
					formReporterOriginal.value = data.user_id
					formConfirmationMessage.value = data.confirmation_text
					formConfirmationMessageOriginal.value = data.confirmation_text

					if (
						data.form_emails.reporting_email !== null &&
						Object.keys(data.form_emails.reporting_email).length
					) {
						formReportingEmailId.value = data.form_emails.reporting_email.id
						formReportingEmailTemplate.value = data.form_emails.reporting_email.email_template_id
						formReportingEmailTemplateOriginal.value = data.form_emails.reporting_email.email_template_id
						formReportingEmailSubject.value = data.form_emails.reporting_email.subject
						formReportingEmailSubjectOriginal.value = data.form_emails.reporting_email.subject
						formReportingEmailMessage.value = data.form_emails.reporting_email.message
						formReportingEmailMessageOriginal.value = data.form_emails.reporting_email.message
					}

					if (
						data.form_emails.confirmation_email !== null &&
						Object.keys(data.form_emails.confirmation_email).length
					) {
						formConfirmationEmailId.value = data.form_emails.confirmation_email.id
						formConfirmationEmailTemplate.value = data.form_emails.confirmation_email.email_template_id
						formConfirmationEmailTemplateOriginal.value =
							data.form_emails.confirmation_email.email_template_id
						formConfirmationEmailSubject.value = data.form_emails.confirmation_email.subject
						formConfirmationEmailSubjectOriginal.value = data.form_emails.confirmation_email.subject
						formConfirmationEmailMessage.value = data.form_emails.confirmation_email.message
						formConfirmationEmailMessageOriginal.value = data.form_emails.confirmation_email.message
					}

					form.value = data.form.field_groups
					formOriginal.value = JSON.stringify(data.form.field_groups)

					console.log('form', form.value)
				})
				.catch(error => {
					// toast.error('Error fetching form:\n' + error.response.data.message)
					toast.error('Error fetching form\n' + error)
				})
				.then(() => {
					root.ctx.loaderHide()
				})
		}

		function addField(index) {
			let group = {}
			group = JSON.parse(JSON.stringify(availableFields.value.fields[index]))

			form.value.push(group)

			console.log('addField', form.value)
		}

		const addFieldGroup = index => {
			let fieldGroup = {}
			fieldGroup = JSON.parse(JSON.stringify(availableFields.value.field_groups[index]))

			fieldGroup.sub_groups.forEach(sub_group => {
				sub_group.fields.forEach(field => {
					field.name = uuidv4()
				})
			})

			console.log('fieldGroup', fieldGroup)

			form.value.push(fieldGroup)

			setTimeout(() => {
				selectField(form.value.length - 1)
			}, 100)
		}

		const componentName = suffix => {
			return 'FormBuilder' + suffix.charAt(0).toUpperCase() + suffix.slice(1)
		}

		const selectField = index => {
			fieldIndex.value = index

			// offset the field settings to be close to the field being edited
			setTimeout(() => {
				fieldSettingsOffset.value = document.getElementById(fieldSettings.value.name).offsetTop
			}, 100)

			if (fieldSettings.value.rules) {
				// fieldSettings.value.rules =
				// 	Object.keys(fieldSettings.value.rules).length !== 0
				// 		? form[index].validation.split('|')
				// 		: []

				if (fieldSettings.value.rules.length > 0) {
					let hasMin = fieldSettings.value.validation.match(/min:/) !== null
					let hasMax = fieldSettings.value.validation.match(/max:/) !== null

					fieldSettings.value.rules.forEach(rule => {
						let min = rule.match(/min:(\d+)/)
						let max = rule.match(/max:(\d+)/)

						if (min) {
							fieldValidationMin.value = min[1]
						}
						if (!hasMin) {
							fieldValidationMin.value = 0
						}

						if (max) {
							fieldValidationMax.value = max[1]
						}
						if (!hasMax) {
							fieldValidationMax.value = 0
						}
					})
				} else {
					fieldValidationMin.value = 0
					fieldValidationMax.value = null
				}
			}

			if (fieldSettings.value.options) {
				updateOptions()
			}

			// Scroll to the new field
			// setTimeout(() => {
			// 	const main = document.getElementById('main-content')

			// 	console.log(fieldSettingsOffset.value)
			// 	main.scrollTo({
			// 		top: 180 + fieldSettingsOffset.value,
			// 		left: 0,
			// 		behavior: 'smooth',
			// 	})
			// }, 100)
		}

		const isFieldSelected = index => {
			return false
			return fieldIndex.value === index
		}

		const fieldSettingAllowImages = computed(() => {
			let mimeIndex = fieldSettings.value.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'mime'
			)
			let mime = fieldSettings.value.field_validations[mimeIndex]

			return mime.rule_value.includes('image/gif,image/jpeg,image/png')
		})

		const fieldSettingAllowVideos = computed(() => {
			let mimeIndex = fieldSettings.value.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'mime'
			)
			let mime = fieldSettings.value.field_validations[mimeIndex]

			return mime.rule_value.includes(
				'video/mp4,video/x-m4v,video/quicktime,video/x-ms-asf,video/x-ms-wmv,application/x-troff-msvideo,video/avi,video/msvideo,video/x-msvideo,video/mpeg,video/ogg'
			)
		})

		const fieldSettingAllowDocuments = computed(() => {
			let mimeIndex = fieldSettings.value.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'mime'
			)
			let mime = fieldSettings.value.field_validations[mimeIndex]

			return mime.rule_value.includes(
				'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text'
			)
		})

		const updateValidationFile = (evt, type) => {
			let checked = evt.target.checked

			let mimeIndex = fieldSettings.value.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'mime'
			)

			let mime = fieldSettings.value.field_validations[mimeIndex]

			if (type === 'images') {
				if (checked) {
					mime.rule_value += ',image/gif,image/jpeg,image/png'
				} else {
					mime.rule_value = mime.rule_value.replace(',image/gif,image/jpeg,image/png', '')
				}
			}

			if (type === 'videos') {
				if (checked) {
					mime.rule_value +=
						',video/mp4,video/x-m4v,video/quicktime,video/x-ms-asf,video/x-ms-wmv,application/x-troff-msvideo,video/avi,video/msvideo,video/x-msvideo,video/mpeg,video/ogg'
				} else {
					mime.rule_value = mime.rule_value.replace(
						',video/mp4,video/x-m4v,video/quicktime,video/x-ms-asf,video/x-ms-wmv,application/x-troff-msvideo,video/avi,video/msvideo,video/x-msvideo,video/mpeg,video/ogg',
						''
					)
				}
			}

			if (type === 'documents') {
				if (checked) {
					mime.rule_value +=
						',application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text'
				} else {
					mime.rule_value = mime.rule_value.replace(
						',application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text',
						''
					)
				}
			}

			mime.rule_value = mime.rule_value.replace(/(^,)|(,$)/g, '')

			// let mimeIndex = null
			// fieldSettings.value.rules.forEach((rule, index) => {
			// 	if (rule.match(/mimes:.*/)) {
			// 		mimeIndex = index
			// 	}
			// })

			// if (mimeIndex !== null) {
			// 	fieldSettings.value.rules.splice(mimeIndex, 1)
			// }

			// if (fieldSettings.value.mimes.length > 0) {
			// 	fieldSettings.value.rules.push('mimes:' + fieldSettings.value.mimes.join(','))
			// }

			// updateValidationRules()
		}

		const updateValidationMinChar = evt => {
			let minIndex = fieldSettings.value.field_validations.findIndex(x => x.field_validation_rule.rule === 'min')

			if (evt.target.value === '') {
				fieldSettings.value.field_validations[minIndex].rule_value =
					fieldSettings.value.field_validations[minIndex].field_validation_rule.default_value
			} else {
				fieldSettings.value.field_validations[minIndex].rule_value = evt.target.value
			}
		}

		const updateValidationMaxChar = evt => {
			let maxIndex = fieldSettings.value.field_validations.findIndex(x => x.field_validation_rule.rule === 'max')

			if (evt.target.value === '') {
				fieldSettings.value.field_validations[maxIndex].rule_value =
					fieldSettings.value.field_validations[maxIndex].field_validation_rule.default_value
			} else {
				fieldSettings.value.field_validations[maxIndex].rule_value = evt.target.value
			}
		}

		watch(
			() => form,
			(form, oldValue) => {
				form.value.forEach((group, groupIndex) => {
					group.sub_groups.forEach((subGroup, subGroupIndex) => {
						// Remove sub groups that have no fields
						if (form.value[groupIndex].sub_groups[subGroupIndex].fields.length == 0) {
							form.value[groupIndex].sub_groups.splice(subGroupIndex, 1)

							// Check if the parent group is now empty and
							// remove if it is
							if (form.value[groupIndex].sub_groups.length == 0) {
								form.value.splice(groupIndex, 1)
							}

							// Clear field settings selection if a field is removed
							fieldSettings.value = {}
						}
					})
				})

				isFormDirty()
			},
			{ deep: true }
		)

		watch(
			[
				formName,
				formCols,
				formInstructions,
				formCaptcha,
				formSubmitlabel,
				forEvent,
				formMaxSubmissions,
				formReporter,
				formConfirmationMessage,
				formReportingEmailTemplate,
				formReportingEmailSubject,
				formReportingEmailMessage,
				formConfirmationEmailTemplate,
				formConfirmationEmailSubject,
				formConfirmationEmailMessage,
			],
			(newValue, oldValue) => {
				// console.log(newValue, oldValue)
				isFormDirty()
			}
		)

		watch(fieldValidationMinDate, (currentValue, oldValue) => {
			validateDateRange(fieldValidationMinDate.value, fieldValidationMaxDate.value)
		})

		watch(fieldValidationMaxDate, (currentValue, oldValue) => {
			validateDateRange(fieldValidationMinDate.value, fieldValidationMaxDate.value)
		})

		const isFormDirty = () => {
			dirty.value = false

			let formCheck = JSON.stringify(form.value)

			if (formOriginal.value !== formCheck) {
				dirty.value = true
				console.log('form DIRTY')
			}

			if (formNameOriginal.value !== formName.value) {
				dirty.value = true
				console.log('formName DIRTY')
			}

			if (formColsOriginal.value !== formCols.value) {
				dirty.value = true
				console.log('formCols DIRTY')
			}

			if (formInstructions.value == '') {
				formInstructions.value = null
			}
			if (formInstructionsOriginal.value !== formInstructions.value) {
				dirty.value = true
				console.log('formInstructions DIRTY')
			}

			if (formCaptchaOriginal.value !== formCaptcha.value) {
				dirty.value = true
				console.log('formCaptcha DIRTY')
			}

			if (formSubmitlabelOriginal.value !== formSubmitlabel.value) {
				dirty.value = true
				console.log('formSubmitlabel DIRTY')
			}

			if (!forEvent.value) {
				if (forEventOriginal.value !== forEvent.value) {
					dirty.value = true
					console.log('forEvent DIRTY')
				}

				if (
					formMaxSubmissionsOriginal.value !== formMaxSubmissions.value &&
					formMaxSubmissionsOriginal.value !== parseInt(formMaxSubmissions.value)
				) {
					dirty.value = true
					console.log('formMaxSubmissions DIRTY', formMaxSubmissionsOriginal.value, formMaxSubmissions.value)
				}

				if (formReporterOriginal.value !== formReporter.value) {
					dirty.value = true
					console.log('formReporter DIRTY')
				}

				if (formConfirmationMessageOriginal.value !== formConfirmationMessage.value) {
					dirty.value = true
					console.log('formConfirmationMessage DIRTY')
				}

				if (formReportingEmailTemplateOriginal.value !== formReportingEmailTemplate.value) {
					dirty.value = true
					console.log('formReportingEmailTemplate DIRTY')
				}

				if (formReportingEmailSubjectOriginal.value !== formReportingEmailSubject.value) {
					dirty.value = true
					console.log('formReportingEmailSubject DIRTY')
				}

				if (formReportingEmailMessageOriginal.value !== formReportingEmailMessage.value) {
					dirty.value = true
					console.log('formReportingEmailMessage DIRTY')
				}

				if (formConfirmationEmailTemplateOriginal.value !== formConfirmationEmailTemplate.value) {
					dirty.value = true
					console.log('formConfirmationEmailTemplate DIRTY')
				}

				if (formConfirmationEmailSubjectOriginal.value !== formConfirmationEmailSubject.value) {
					dirty.value = true
					console.log('formConfirmationEmailSubject DIRTY')
				}

				if (formConfirmationEmailMessageOriginal.value !== formConfirmationEmailMessage.value) {
					dirty.value = true
					console.log('formConfirmationEmailMessage DIRTY')
				}
			}
		}

		const validateDateRange = (start, end) => {
			dateRangeError.value = false

			if (start && end && (end < start || end.toDateString() == start.toDateString())) {
				dateRangeError.value = true
			}

			if (start) {
				fieldSettings.value.min = start
			} else {
				fieldSettings.value.min = null
			}

			if (end) {
				fieldSettings.value.max = end
			} else {
				fieldSettings.value.max = null
			}
		}

		watch(formStartDate, (currentValue, oldValue) => {
			validateFormDateRange(formStartDate.value, formEndDate.value)
		})

		watch(formEndDate, (currentValue, oldValue) => {
			validateFormDateRange(formStartDate.value, formEndDate.value)
		})

		const validateFormDateRange = (start, end) => {
			dateRangeFormError.value = false

			if (start && end && (end < start || end.toDateString() == start.toDateString())) {
				dateRangeFormError.value = true
			}
		}

		const processData = () => {
			let fieldGroups = JSON.parse(JSON.stringify(form.value))
			console.log('fieldGroups', fieldGroups)

			let fieldCols = []
			let fieldGroupsTmp = []

			fieldGroups.forEach((fieldGroup, index) => {
				console.log('fieldGroup', fieldGroup)
				fieldGroup.sub_groups.forEach((fieldSubGroup, fieldSubGroupIndex) => {
					console.log('fieldSubGroup', fieldSubGroup)
					fieldGroups[index].sub_groups[fieldSubGroupIndex].column_count = parseInt(
						fieldSubGroup.column_count
					)

					// fieldSubGroup.fields.forEach((field, fieldIndex) => {
					// 	console.log('field', field)
					// })
				})

				fieldGroupsTmp.push(JSON.parse(JSON.stringify(fieldGroup)))

				if ((index + 1) % parseInt(formCols.value) === 0 || index === fieldGroups.length - 1) {
					fieldCols.push({
						id: parseInt(fieldGroup.id ?? null),
						column_count: parseInt(formCols.value),
						sub_groups: [],
					})

					fieldCols[fieldCols.length - 1].sub_groups = fieldGroupsTmp

					fieldGroupsTmp = []
				}
			})

			// console.log(fieldCols)

			// fieldGroups = JSON.parse(JSON.stringify(fieldCols))

			let data = {
				name: formName.value,
				form_revision_id: formRevisionId.value,
				instructions: formInstructions.value,
				submit_label: formSubmitlabel.value,
				captcha: formCaptcha.value,
				column_count: parseInt(formCols.value),

				for_event: forEvent.value,
				max_submissions: formMaxSubmissions.value,
				user_id: formReporter.value,
				confirmation_text: formConfirmationMessage.value,

				confirmation_email: {
					id: formConfirmationEmailId.value ?? null,
					email_template_id: formConfirmationEmailTemplate.value,
					subject: formConfirmationEmailSubject.value,
					message: formConfirmationEmailMessage.value,
				},

				reporting_email: {
					id: formReportingEmailId.value ?? null,
					email_template_id: formReportingEmailTemplate.value,
					subject: formReportingEmailSubject.value,
					message: formReportingEmailMessage.value,
				},

				field_groups: fieldGroups,
			}

			return data
		}

		const update = () => {
			let processedData = processData()
			console.log('form', form)
			console.log(processedData)
			console.log(JSON.stringify(processedData))
			root.ctx.loaderShow()

			api.forms
				.slug(formID.value)
				.update(processedData)
				.then(data => {
					formPublished.value = data.form_revision_published
					formRevisionId.value = data.form.id
					formCols.value = data.column_count.toString()
					formColsOriginal.value = data.column_count.toString()
					formName.value = data.name
					formNameOriginal.value = data.name
					formInstructions.value = data.instructions
					formInstructionsOriginal.value = data.instructions
					formSubmitlabel.value = data.submit_label
					formSubmitlabelOriginal.value = data.submit_label
					formCaptcha.value = data.captcha
					formCaptchaOriginal.value = data.captcha

					forEvent.value = data.for_event
					forEventOriginal.value = data.for_event
					formMaxSubmissions.value = data.max_submissions
					formMaxSubmissionsOriginal.value = data.max_submissions
					formReporter.value = data.user_id
					formReporterOriginal.value = data.user_id
					formConfirmationMessage.value = data.confirmation_text
					formConfirmationMessageOriginal.value = data.confirmation_text

					if (
						data.form_emails.reporting_email !== null &&
						Object.keys(data.form_emails.reporting_email).length
					) {
						formReportingEmailId.value = data.form_emails.reporting_email.id
						formReportingEmailTemplate.value = data.form_emails.reporting_email.email_template_id
						formReportingEmailTemplateOriginal.value = data.form_emails.reporting_email.email_template_id
						formReportingEmailSubject.value = data.form_emails.reporting_email.subject
						formReportingEmailSubjectOriginal.value = data.form_emails.reporting_email.subject
						formReportingEmailMessage.value = data.form_emails.reporting_email.message
						formReportingEmailMessageOriginal.value = data.form_emails.reporting_email.message
					}

					if (
						data.form_emails.confirmation_email !== null &&
						Object.keys(data.form_emails.confirmation_email).length
					) {
						formConfirmationEmailId.value = data.form_emails.confirmation_email.id
						formConfirmationEmailTemplate.value = data.form_emails.confirmation_email.email_template_id
						formConfirmationEmailTemplateOriginal.value =
							data.form_emails.confirmation_email.email_template_id
						formConfirmationEmailSubject.value = data.form_emails.confirmation_email.subject
						formConfirmationEmailSubjectOriginal.value = data.form_emails.confirmation_email.subject
						formConfirmationEmailMessage.value = data.form_emails.confirmation_email.message
						formConfirmationEmailMessageOriginal.value = data.form_emails.confirmation_email.message
					}

					form.value = data.form.field_groups
					formOriginal.value = JSON.stringify(data.form.field_groups)

					toast.success('Successfully updated form')
				})
				.catch(error => {
					// toast.error('Error updating form:\n' + error.response.data.message)
					toast.error({
						component: ToastsFormError,
						props: {
							errors: error.response.data.errors,
						},
					})
				})
				.then(() => {
					root.ctx.loaderHide()
				})
		}

		const publish = () => {
			root.ctx.loaderShow()

			api.forms
				.slug(formRevisionId.value)
				.publish()
				.then(data => {
					formPublished.value = data.form_revision_published
					formRevisionId.value = data.form.id
					formCols.value = data.column_count.toString()
					formColsOriginal.value = data.column_count.toString()
					formName.value = data.name
					formNameOriginal.value = data.name
					formInstructions.value = data.instructions
					formInstructionsOriginal.value = data.instructions
					formSubmitlabel.value = data.submit_label
					formSubmitlabelOriginal.value = data.submit_label
					formCaptcha.value = data.captcha
					formCaptchaOriginal.value = data.captcha

					form.value = data.form.field_groups
					formOriginal.value = JSON.stringify(data.form.field_groups)
				})
				.catch(error => {
					toast.error('Error updating form:\n' + error.response.data.message)
				})
				.then(() => {
					root.ctx.loaderHide()
				})
		}

		const archive = () => {
			root.ctx.loaderShow()

			api.forms
				.slug(formPublished.value.id)
				.archive()
				.then(data => {
					formPublished.value = data.form_revision_published
					formRevisionId.value = data.form.id
					formCols.value = data.column_count.toString()
					formColsOriginal.value = data.column_count.toString()
					formName.value = data.name
					formNameOriginal.value = data.name
					formInstructions.value = data.instructions
					formInstructionsOriginal.value = data.instructions
					formSubmitlabel.value = data.submit_label
					formSubmitlabelOriginal.value = data.submit_label
					formCaptcha.value = data.captcha
					formCaptchaOriginal.value = data.captcha

					form.value = data.form.field_groups
					formOriginal.value = JSON.stringify(data.form.field_groups)
				})
				.catch(error => {
					toast.error('Error updating form:\n' + error.response.data.message)
				})
				.then(() => {
					root.ctx.loaderHide()
				})
		}

		const updateValidationRules = () => {
			if (fieldSettings.value.field_type.name === 'group') {
				fieldSettings.value.children.forEach((child, index) => {
					if (child.type) {
						fieldSettings.value.children[index].validation = fieldSettings.value.children[index].rules.join(
							'|'
						)
					}
					if (child.children) {
						child.children.forEach((subChild, subIndex) => {
							if (subChild.type) {
								fieldSettings.value.children[index].children[subIndex].validation = form[
									fieldIndex.value
								].children[index].children[subIndex].rules.join('|')
							}
						})
					}
				})
			} else if (fieldSettings.value.field_type.name === 'tel') {
				fieldSettings.value.validation = '[' + fieldSettings.value.rules.join(',') + ']'
			} else {
				fieldSettings.value.validation = fieldSettings.value.rules.join('|')
			}
		}

		const resetFieldSettings = () => {
			fieldIndex.value = -1
		}

		const updateField = evt => {
			let value = evt.target.value.trim()

			if (value !== '') {
				form[fieldIndex.value][evt.target.name] = value
			} else if (form[fieldIndex.value][evt.target.name]) {
				delete form[fieldIndex.value][evt.target.name]
			}
		}

		const duplicateField = () => {
			let field = JSON.parse(JSON.stringify(form[fieldIndex.value]))
			field.name = uuidv4()

			form.value.splice(fieldIndex.value + 1, 0, field)
			selectField(fieldIndex.value + 1)
		}

		const deleteField = () => {
			form.value.splice(fieldIndex.value, 1)
		}

		const updateOptionValue = index => {
			fieldSettings.value.field_choices[index].value = fieldSettings.value.field_choices[index].label
				.toLowerCase()
				.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi, '')
				.replace(/ +/g, '-')

			updateOptions()
		}

		const addOption = index => {
			let optionCount = fieldSettings.value.field_choices.length

			let defaultOption = {
				choice_value: 'New Choice',
			}

			if (index + 1 >= optionCount) {
				fieldSettings.value.field_choices.push(defaultOption)
			} else {
				fieldSettings.value.field_choices.splice(index + 1, 0, defaultOption)
			}

			updateOptions()
		}

		const deleteOption = index => {
			fieldSettings.value.field_choices.splice(index, 1)

			updateOptions()
		}

		const updateOptions = index => {
			let options = {}

			fieldSettings.value.field_choices.forEach(option => {
				options[option.value] = option.label
			})

			fieldSettings.value.options = options
		}

		const itemMoved = evt => {
			resetFieldSettings()
		}

		const updateReportingField = (evt, value) => {
			if (evt.target.checked) {
				reportingFields.push(value)
			} else {
				reportingFields.splice(reportingFields.indexOf(value), 1)
			}
			console.log(reportingFields)
		}

		const uuidv4 = () => {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (Math.random() * 16) | 0,
					v = c == 'x' ? r : (r & 0x3) | 0x8
				return v.toString(16)
			})
		}

		const array_move = (arr, old_index, new_index) => {
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1
				while (k--) {
					arr.push(undefined)
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
			return arr
		}

		const openPreview = () => {
			let previewData = processData().field_groups
			console.log(previewData)
			// preview.value = JSON.stringify(previewData)
			document.getElementById('preview-data').value = JSON.stringify(previewData)
			document.getElementById('preview').submit()
		}

		const hasFieldSettings = computed(
			() =>
				(typeof form[fieldIndex.value] === 'undefined' && Object.keys(fieldSettings).length === 0) ||
				(typeof form[fieldIndex.value] !== 'undefined' && Object.keys(form[fieldIndex.value]).length === 0)
		)

		const isRequired = computed(() => {
			let requiredIndex = fieldSettings.value.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'required'
			)

			return fieldSettings.value.field_validations[requiredIndex].rule_value === 'true'
		})

		const updateValidationRequired = evt => {
			let requiredIndex = fieldSettings.value.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'required'
			)

			// console.log(fieldSettings.value.field_validations[requiredIndex].rule_value, evt.target.checked.toString())

			fieldSettings.value.field_validations[requiredIndex].rule_value = evt.target.checked.toString()
		}

		const formValid = computed(() => {
			return true
			// return (
			// 	form.length > 0 &&
			// 	!dateRangeError.value &&
			// 	!dateRangeFormError.value &&
			// 	reportingMethod.value &&
			// 	reportingEmail.value &&
			// 	reportingFields.length > 0 &&
			// 	reportingEmailSubject.value &&
			// 	formName.value &&
			// 	formSubmitlabel.value &&
			// 	(formMaxSubmissions.value ? formMaxSubmissionsMessage.value : true) &&
			// 	formConfirmationMessage.value &&
			// 	formConfirmationEmailTemplate.value &&
			// 	formConfirmationEmailSubject.value &&
			// 	formConfirmationEmailMessage.value
			// )
		})

		const editField = field => {
			fieldSettings.value = {}

			setTimeout(() => {
				fieldSettings.value = field

				let minIndex = field.field_validations.findIndex(x => x.field_validation_rule.rule === 'min')
				if (minIndex != -1) {
					fieldValidationMin.value = fieldSettings.value.field_validations[minIndex].rule_value
				}

				let maxIndex = field.field_validations.findIndex(x => x.field_validation_rule.rule === 'max')
				if (maxIndex != -1) {
					fieldValidationMax.value = fieldSettings.value.field_validations[maxIndex].rule_value
				}
			}, 100)
		}

		return {
			forEvent,
			dirty,
			addField,
			addFieldGroup,
			componentName,
			fieldIndex,
			fieldSettings,
			fieldValidationMax,
			fieldValidationMin,
			drag,
			form,
			formPublished,
			formCols,
			formName,
			formInstructions,
			formCaptcha,
			hasFieldSettings,
			isFieldSelected,
			isRequired,
			itemMoved,
			selectField,
			updateField,
			updateValidationMaxChar,
			updateValidationMinChar,
			updateValidationRequired,
			fieldSettingsOffset,
			duplicateField,
			deleteField,
			addOption,
			deleteOption,
			updateOptions,
			updateOptionValue,
			dateRangeError,
			fieldValidationMinDate,
			fieldValidationMaxDate,
			update,
			publish,
			archive,
			formValid,
			updateValidationFile,
			dateRangeFormError,
			formStartDate,
			formEndDate,
			updateReportingField,
			formSubmitlabel,

			formMaxSubmissions,
			formReporter,
			formConfirmationMessage,

			formReportingEmailTemplate,
			formReportingEmailSubject,
			formReportingEmailMessage,

			formConfirmationEmailTemplate,
			formConfirmationEmailSubject,
			formConfirmationEmailMessage,

			openPreview,
			previewUrl,
			preview,
			availableFields,

			editField,
			fieldSettingAllowImages,
			fieldSettingAllowVideos,
			fieldSettingAllowDocuments,
		}
	},
}
</script>
